import React, { Component } from "react";
import { Image, Loader, Modal } from "semantic-ui-react";
import cookie from "react-cookies";
import { navigate } from "gatsby";
import i18next from "i18next";
import { formatQuery } from "../../controllers/utilities/string-utils";

export function keyToDisplay(key) {
    switch (key.toUpperCase()) {
        case 'TWD':
            return i18next.t("SDK.NTD");
        case 'USD':
            return i18next.t("SDK.USD");
        case 'RMB':
        case 'CNY':
            return i18next.t("SDK.RMB");
        case 'MYR':
            return i18next.t("SDK.MYR");
        case 'HKD':
            return i18next.t("SDK.HKD");
        default:
            return key.toUpperCase();
    }
}

export class CurrencyButtonBase extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            clicked: false
        };
    }

    render() {
        return (
            <React.Fragment>
                <Modal basic open={this.state.clicked} size='small'>
                    <Modal.Content>
                        <Loader />
                    </Modal.Content>
                </Modal>
                <div style={{ margin: "-0.5rem" }}>
                    <Image style={{
                        opacity: this.state.clicked ? 0.7 : 1,
                    }} src={this.props.src} onClick={() => {
                        this.setState({
                            clicked: true
                        });
                        const q = cookie.load("_q");
                        if (q) {
                            let { productId } = formatQuery(q);
                            if (productId) {
                                navigate(`/payment-providers/?productId=${productId}&currency=${this.props.currency}`);
                            } else {
                                navigate(`/credit-packages/?currency=${this.props.currency}`);
                            }
                        } else {
                            navigate(`/credit-packages/?currency=${this.props.currency}`);
                        }

                        window.scrollTo(0, 0);
                    }} />
                </div>
            </React.Fragment>
        );
    }
}