import React from "react";
import i18next from "i18next";
import {Button, Dimmer, Divider, Header, Image, Loader} from "semantic-ui-react";
import withTrans from "../../i18n/withTrans";
import {formatQuery} from "../../controllers/utilities/string-utils";
import {setAdItem, ImgVideoSmall} from "../lib/ad-manage-api-lib";
import result_success from "../../images/result_success.jpg";
import result_fail1 from "../../images/result_fail1.jpg";
import result_fail2 from "../../images/result_fail2.jpg";
import {keyToDisplay} from "../lib/currency-button-base";
import {Link} from "gatsby";
import {navigate} from "../../../.cache/gatsby-browser-entry";
import cookie from "react-cookies";

export default  withTrans((props) => {
    const {ready, status, gold, price, currency, createdAt, updatedAt, showTransactionId,balance, userId, productId, isQueryOrder, message, adimage, adurl,adeventId, onContinue, onGameSupport, onPaymentSupport, oldsuccess, oldmessage, t} = props;
    let oldmessage1 = oldmessage;
    if (!oldmessage1) oldmessage1 = "";
    const {transactionId, accessToken, gameId, attributionId} = formatQuery(props.q);
    // console.log(accessToken);

    // let queryString = props.q;
    // if(queryString.startsWith("?"))
    //     queryString = queryString.substring(1);
    // const list = queryString.split("&");
    // let newGameId = "";
    // let newaccessToken = "";
    // if (list.length > 0) {
    //     let pairs = list[0].split("=");
    //     newGameId = pairs[1];
    //     pairs = list[3].split("=");
    //     newaccessToken = pairs[1];
    // }
    // console.log("ccccccc");
    // console.log(adimage);
    let adItem1 = { "adimg":"", "adlink":"","eventId":"" };
    adItem1.adimg = adimage;
    adItem1.adlink = adurl;
    adItem1.eventId = adeventId;

    if (currency !== undefined) {
        let _message = `${i18next.t("PackName")}：OP禮包(${gold}OP幣)\n\n`;
        console.log(currency);
        _message += `支付金額：${price * (-1)} ${keyToDisplay(currency)}\n\n`;
        _message += `创建時間：${dateFormat(createdAt, "yyyy-MM-dd hh:mm")} (GMT+8)\n\n`;
        if (status === "Completed") {
            _message += `第三方支付訂單號：${showTransactionId}\n\n`;
            _message += `支付時間：${dateFormat(updatedAt, "yyyy-MM-dd hh:mm")} (GMT+8)\n\n`;
            if (isQueryOrder === "query"){
                _message += `延遲交易，增值成功\n\n`;
            }
        }
        let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);
        cookie.save("_message", _message, {path: "/", expires: inFifteenMinutes});
        // cookie.save("_status", status, {path: "/", expires: inFifteenMinutes});
    }
    let showReturnBtn = cookie.load("_showReturnBtn");
    return (
        <div style={{"width":"100vw", "height":"100%"}}>
            <div   style={{backgroundColor: 'white', "width":"100vw", "height":"100vh"}}>
                {ready &&
                (<>
<div style={{width:"100%", float:"left", paddingTop: "10px"}}>
    {(price > 0) && (status === "Completed") && (<div style={{"width":"100vw",textAlign:"center",fontSize: '2rem', padding: '5px', fontWeight: "bold",paddingBottom:"20px"}}>{price * (-1)} {keyToDisplay(currency)}
    </div>)}
    {(isQueryOrder === "query") && ((productId !== "67dab3be-2022-4e70-0221-vipacc63c001")  && (productId !== "67dab3be-2022-4e70-0221-vipacc63c002") && (productId !== "67dab3be-2022-4e70-0221-vipacc63c003") && (productId !== "67dab3be-2021-4e70-1014-895acc63c001") && (productId !== "67dab3be-2021-4e70-1026-895acc63c001") && (productId !== "67dab3be-2021-4e70-1026-895acc63c002") && (productId !== "67dab3be-2021-4e70-1014-895acc63c003") && (productId !== "67dab3be-2021-4e70-1014-895acc63c002") && (productId !== "67dab3be-2021-4e70-1014-895acc63c004") && (productId !== "67dab3be-2022-4e70-0314-895acc63c004") && (productId !== "67dab3be-2021-4e70-1026-895acc63c003") && (productId !== "67dab3be-2022-4e70-0314-895acc63c003")) &&(
        <div style={{"width":"100vw",textAlign:"center",fontSize: '1rem',paddingBottom:"5px",color:"#FF9500"}}>
            延遲交易，增值成功<br/>
            {gold} 已存入你的錢包
        </div>
    )}
 {(isQueryOrder === "query") && ((productId === "67dab3be-2021-4e70-1014-895acc63c001") || (productId === "67dab3be-2021-4e70-1026-895acc63c001") || (productId === "67dab3be-2021-4e70-1026-895acc63c002") || (productId === "67dab3be-2021-4e70-1014-895acc63c003")) && (
        <div style={{"width":"100vw",textAlign:"center",fontSize: '1rem',paddingBottom:"5px",color:"#FF9500"}}> 周卡延遲交易，已成功購買<br/>
將會在每天00:15 GMT+8 到帳</div>
                )}
                {(isQueryOrder === "query") && ((productId === "67dab3be-2021-4e70-1014-895acc63c002") || (productId === "67dab3be-2021-4e70-1014-895acc63c004") || (productId === "67dab3be-2022-4e70-0314-895acc63c004") || (productId === "67dab3be-2021-4e70-1026-895acc63c003") || (productId === "67dab3be-2022-4e70-0314-895acc63c003")) && (
        <div style={{"width":"100vw",textAlign:"center",fontSize: '1rem',paddingBottom:"5px",color:"#FF9500"}}> 月卡延遲交易，已成功購買<br/>
將會在每天00:15 GMT+8 到帳</div>
                )}
                {(isQueryOrder === "query") && ((productId === "67dab3be-2022-4e70-0221-vipacc63c001") || (productId === "67dab3be-2022-4e70-0221-vipacc63c002") || (productId === "67dab3be-2022-4e70-0221-vipacc63c003")) && (
        <div style={{"width":"100vw",textAlign:"center",fontSize: '1rem',paddingBottom:"5px",color:"#FF9500"}}> VIP订阅延遲交易，已成功購買<br/>
將會在每天00:15 GMT+8 到帳</div>
                )}

    {(status !== "Completed") && (decodeURI(message).indexOf("交易過於頻繁") === -1) && (decodeURI(message).indexOf("忙碌中") === -1) && (
        <div style={{"width":"100vw",textAlign:"center",fontSize: '2rem', padding: '10px', fontWeight: "bold",paddingBottom:"10px", paddingTop: "20px"}}>交易失敗
            <div style={{textAlign:"left",fontSize: '1rem',paddingLeft:"10px", paddingTop:"10px",fontWeight:"normal",color:"red"}}>
                如支付渠道實際扣款成功，請按以下步驟來處理：<br/>
                1. 10~15分鐘內會進行補發；查看補發狀態路徑: 主介面>帳號管理>帳單詳情<br/>
                2. 如15分鐘後沒收到補發，請截圖聯繫線上客服查詢帳單；線上客服路徑：主介面>帳號管理>聯繫客服
            </div>
        </div>
    )}
    {(status !== "Completed") && (decodeURI(message).indexOf("交易過於頻繁") >= 0) && (
        <div style={{"width":"100vw",textAlign:"center",fontSize: '2rem', padding: '10px', fontWeight: "bold",paddingBottom:"10px", paddingTop: "20px"}}>交易失敗
            <div style={{textAlign:"left",fontSize: '1rem',paddingLeft:"10px", paddingTop:"10px",fontWeight:"normal",color:"red"}}>
                因系統檢測到頻繁交易失敗紀錄，禁止支付10分鐘。<br/>

                解封時間: {dateFormat(parseInt(decodeURI(message).split("|")[1]), "dd/MM/yyyy hh:mm:ss")} GMT+8<br/>

                若有疑問，請聯繫線上客服。<br/>
                線上客服路徑：主介面>帳號管理>聯繫客服
            </div>
        </div>
    )}
    {(status !== "Completed") && (decodeURI(message).indexOf("忙碌中") >= 0) && (
        <div style={{"width":"100vw",textAlign:"center",fontSize: '2rem', padding: '10px', fontWeight: "bold",paddingBottom:"10px", paddingTop: "20px"}}>支付忙碌，30秒重試
            <div style={{textAlign:"left",fontSize: '1rem',paddingLeft:"10px", paddingTop:"10px",fontWeight:"normal",color:"red"}}>
                本支付方式忙碌中，請稍後再試，若有疑問，請聯繫線上客服。<br/>
                線上客服路徑：主介面>帳號管理>聯繫客服
            </div>
        </div>
    )}
    <div style={{textAlign:"center",width:"90vw",margin:"auto",paddingBottom:"2px"}}>
        <div style={{width:"90vw",borderTop:"1px solid #F3F3F3"}}></div>
    </div>
</div>
    <div style={{width:"50%", float:"left", paddingTop: "2px"}}>

    <div>
        <div>
            <div style={{width:"35%",float:"left",textAlign:"right", padding:"4px", color:"#888", fontSize: '1.1rem'}}>當前狀態：</div>
            <div style={{width:"65%",float:"right",textAlign:"left", padding:"4px", fontSize: '1.1rem'}}
                 dangerouslySetInnerHTML={{__html: resolveTransactionStatus(status) }}
            ></div>
        </div>
        {
            (status !== "Unknown") && (<>
        <div>
            <div style={{width:"35%",float:"left",textAlign:"right", padding:"4px", color:"#888", fontSize: '1.1rem'}}>商品：</div>
            <div style={{width:"65%",float:"right",textAlign:"left", padding:"4px", fontSize: '1.1rem'}}>
                {(gold === 0) && (
                    <span>
                                 {t(decodeURI(message))}
                             </span>
                )}
                {(gold > 0) && (
                    <span>
                                 禮包({gold})
                             </span>
                )}
            </div>
        </div>
            </>)}
        {
            (status === "Completed") && (<>
        <div>
            <div style={{width:"35%",float:"left",textAlign:"right", padding:"4px", color:"#888", fontSize: '1.1rem'}}>支付時間：</div>
            <div style={{width:"65%",float:"right",textAlign:"left", padding:"4px", fontSize: '1.1rem'}}>{dateFormat(updatedAt, "yyyy-MM-dd hh:mm")} (GMT+8)</div>
        </div>
                    <div>
                        <div style={{width:"35%",float:"left",textAlign:"right", padding:"4px", color:"#888", fontSize: '1.1rem'}}>錢包：</div>
                        <div style={{width:"65%",float:"right",textAlign:"left", padding:"4px", fontSize: '1.1rem'}}>{balance}
                            {(isQueryOrder !== "query") &&(<>
                                {((balance-gold) > 0) && (<><span>({balance-gold} + {gold})</span></>)}
                            </>)}
                        </div>
                    </div>
                </>
            )}
        {
            (status !== "Completed") && (createdAt) && (<>
                <div>
                    <div style={{width:"35%",float:"left",textAlign:"right", padding:"4px", color:"#888", fontSize: '1.1rem'}}>創建時間：</div>
                    <div style={{width:"65%",float:"right",textAlign:"left", padding:"4px", fontSize: '1.1rem'}}>{dateFormat(createdAt, "yyyy-MM-dd hh:mm")} (GMT+8)</div>
                </div>
            </>)}
        {
            (status !== "Unknown") && (<>
        <div>
            <div style={{width:"35%",height:"7vw",float:"left",textAlign:"right", padding:"4px", color:"#888", fontSize: '1.1rem'}}>交易訂單：</div>
            <div style={{width:"65%",height:"7vw",float:"right",textAlign:"left", padding:"4px", fontSize: '1.1rem', wordWrap:"break-word"}}>{showTransactionId}</div>
        </div></>)}
        {
            (status === "Unknown") && (<>
                <div>
                    <div style={{width:"35%",height:"7vw",float:"left",textAlign:"right", padding:"4px", color:"#888", fontSize: '1.1rem'}}>Message：</div>
                    <div style={{width:"65%",height:"7vw",float:"right",textAlign:"left", padding:"4px", fontSize: '1.1rem', wordWrap:"break-word"}}>{decodeURI(message).split("|")[0]}</div>
                </div>
            </>)}
        {(userId) && (
        <div>
            <div style={{width:"35%",height:"7vw",float:"left",textAlign:"right", padding:"4px", color:"#888", fontSize: '1.1rem'}}>平台帳號：</div>
            <div style={{width:"65%",height:"7vw",float:"right",textAlign:"left", padding:"4px", fontSize: '1.1rem'}}>{userId}</div>
        </div>)}
    </div>

</div>
    <div style={{width:"50%",float:"right",textAlign:"center",padding:"18px"}}>
<div
        onClick={onGameSupport}
        style={{paddingTop:"100px",padding:"10px"}}>&nbsp;
        <div style={{float:"left", marginBottom:"4px"}}>聯繫商戶</div>
        <div style={{float:"right", padding:"0px"}}>
            <div style={{float:"right", width:"7px",height:"7px",borderTop:"2px solid #C4C4C4",borderRight:"2px solid #C4C4C4",transform:"rotate(45deg)"}}></div>
        </div>
        <div style={{float:"left", textAlign:"center",width:"40vw",margin:"auto",paddingTop:"2px"}}>
            <div style={{width:"100%",borderTop:"1px solid #F3F3F3"}}></div>
        </div>
    </div>
        <div
            onClick={onPaymentSupport}
            style={{width:"100%", padding:"10px"}}
        >&nbsp;
            <div style={{float:"left", marginBottom:"4px"}}>對訂單有疑惑</div>
            <div style={{float:"right", padding:"0px"}}>
                <div style={{float:"right", width:"7px",height:"7px",borderTop:"2px solid #C4C4C4",borderRight:"2px solid #C4C4C4",transform:"rotate(45deg)"}}></div>
            </div>
            <div style={{float:"left", textAlign:"center",width:"40vw",margin:"auto",paddingTop:"2px"}}>
                <div style={{width:"100%",borderTop:"1px solid #F3F3F3"}}></div>
            </div>
        </div>
        {(showReturnBtn !== "1") && 
                            (<div
                                style={{width:"100%", padding:"0px"}}>&nbsp;
                               <Button fluid
                                       onClick={onContinue}
                               >
                                   繼續交易
                               </Button>
                           </div>)}
        {(showReturnBtn === "1") && 
                            (<div
                                style={{width:"100%", padding:"0px"}}>&nbsp;
                               <Button fluid
                                       onClick={() => {
                                        navigate(`/sdk-callback`);
                                    }}
                               >
                                   返回主頁
                               </Button>
                           </div>)}

                </div></>)}
            </div>
        </div>
    );
});

function resolveTransactionStatus(status) {
    switch(status) {
        case "Created":
            return "<span style='color:red'>增值失败</span>";
        case "Confirmed":
            return "支付失敗";
        case "Completed":
            return "增值成功";
        default:
            return "增值失敗";
    }
}

function dateFormat(time, fmt) { // author: meizz
    const date = new Date(time);
    // console.log(date);
    let o = {
        "M+": date.getMonth() + 1, // 月份
        "d+": date.getDate(), // 日
        "h+": date.getHours(), // 小时
        "m+": date.getMinutes(), // 分
        "s+": date.getSeconds(), // 秒
        "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
        "S": date.getMilliseconds() // 毫秒
    };
    if(/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for(let k in o)
        if(new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}