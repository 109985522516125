import React, {Component} from "react";
import {connect} from "react-redux";
import withLocation from "../wrappers/with-location";
import {formatQuery} from "../../controllers/utilities/string-utils";
import KaisakuApi from "../../controllers/kaisaku-api";
import {navigate} from "gatsby";
import View from "./payment-result-new-view";
import {getAdPItem} from "../lib/ad-manage-api-lib";
import {rejectIfAnyNull} from "../../controllers/utilities/env-checks";
import cookie from "react-cookies";
import {getProfile} from "../../pages/game-support";

class PaymentResult extends Component {
    constructor(props, context) {
        super(props, context);
        //console.log("222");
        //console.log(this.props.q);
        this.state = {
            ready: false,
            adItem1:  { "adimg":"", "adlink":"", "eventId":""}
        };
        // console.log("123");
        // console.log(this.props);
        // console.log(this.state);
    }

    async componentDidMount() {
        // saveToCookie(this.props.location.search);
        let {transactionId, success, message} = formatQuery(this.props.location.search);
        // console.log("11");
        // console.log(this.props.location.search);
        // console.log(this.props);
        // console.log(this.state);
        let newaccessToken = "";
        if ((this.props.session !== null) && (this.props.session.accessToken !== null)){
            // console.log("111");
            newaccessToken = this.props.session.accessToken;
        }else{
            let queryString = this.props.location.search;
            if(queryString.startsWith("?"))
                queryString = queryString.substring(1);
            const list = queryString.split("accessToken=");
            // console.log("222");
            if (list.length > 1) {
                // console.log("123321");
                // console.log(list);
                if (list.length >= 1) {
                    let pairs = list[1].split("&");
                    newaccessToken = pairs[0];
                }
            }
        }
        let isIOS = "0";
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
            // 暂时去掉ios两板功能
            isIOS = "1";
        }
        // const res = await getAdPItem(newaccessToken, isIOS);
        // // console.log("ooo");
        // // console.log(res.adP1Image);
        // this.setState({
        //     adItem1:  { "adimg": res.adP1Image, "adlink": res.adP1Url ,"eventId": res.eventId}
        // });
        if ((!success) || (success.toLowerCase() === "true")) {
            let data;
            // if (newaccessToken){
            //     data = await KaisakuApi.getCreditTransactionDetails(transactionId, newaccessToken);
            // }else{
            //     data = await KaisakuApi.getCreditTransactionStatus(transactionId);
            // }
            // 是否显示延迟订单的逻辑，是，读取要显示的延迟订单
            let isQueryOrder = "";
            if (transactionId === "showqueryorder"){
                transactionId = cookie.load("_datatranstransactionId");
                isQueryOrder = "query";
            }
            data = await KaisakuApi.getCreditTransactionDetails(transactionId);
            // console.log(data.transaction);
            if(data.transaction) {
                if (!message) message = "";
                this.setState({
                    ready: true,
                    status: data.transaction.status,
                    transactionId,
                    gold: data.transaction.gold,
                    balance: data.transaction.balance,
                    price: data.transaction.price,
                    currency: data.transaction.currency,
                    createdAt: data.transaction.createdAt,
                    updatedAt: data.transaction.updatedAt,
                    showTransactionId: data.transaction.showTransactionId,
                    isQueryOrder: isQueryOrder,
                    userId: data.transaction.userId,
                    productId: data.transaction.productId,
                    message,
                    oldsuccess:success,
                    oldmessage:message
                });
            } else {
                this.setState({
                    ready: true,
                    status: "Unknown",
                    transactionId,
                    gold: 0,
                    balance: 0,
                    price: "",
                    currency: "",
                    createdAt: "",
                    updatedAt: "",
                    showTransactionId: "",
                    isQueryOrder: "",
                    userId: "",
                    message: data.message,
                    oldsuccess:success,
                    oldmessage:message
                });
            }
        } else {
            this.setState({
                ready: true,
                status: "Unknown",
                transactionId,
                message
            });
        }
    }

    async onContinue() {
        let _accessToken = cookie.load("_accessToken");
        if (_accessToken){
            const _gourlparams = cookie.load("_gourlparams");
            if ((this.state.message) &&(decodeURI(this.state.message).indexOf('忙碌中') !== -1) && (_gourlparams) && (_gourlparams !== "")){
                cookie.save("_gourlparams", "", {
                    path:"/"
                });
                navigate("/payment-providers" + _gourlparams);
                return;
            }
            // 是不是显示延迟订单，显示延迟订单的逻辑
            if (this.state.isQueryOrder !== "query"){
                if (this.state.status === "Completed"){
                    const accessToken = cookie.load("_accessToken");
                    const res = await getProfile(accessToken);
                    const data = res.data;
                    if ((!data.email) && (!data.showbindemail)){
                        navigate("/account-configs-page1/" + this.props.q);
                        return;
                    }
                    navigate("/unionpay-page1/" + this.props.q + "&re=5");
                    return;
                }
                navigate("/credit-purchase-ok-result/" + this.props.q);
            }else {
                if (await queryShowNext()){
                    // let _datatransindex = cookie.load("_datatransindex");
                    // navigate("/credit-purchase-result/?transactionId=showqueryorder＆r=" + _datatransindex);
                    window.location.reload();
                }else {
                    let {pendingTransactionId, newAccessToken, newGameId, newAttributionId} = formatQuery(this.props.q);
                    if (((newAccessToken === undefined) && (newAccessToken !== null)) || ((newGameId === undefined) && (newGameId !== null))) {
                        newAccessToken = cookie.load("_accessToken");
                        newGameId = cookie.load("_gameId");
                        newAttributionId = cookie.load("_attributionId");
                        pendingTransactionId = cookie.load("_transactionId");
                    }
                    if ((newAccessToken !== undefined) && (newGameId !== undefined) && (newAccessToken !== null) && (newGameId !== null)) {
                        let platform = cookie.load("_platform");
                        const url = `/confirm-purchase/?gameId=${newGameId}&attributionId=${newAttributionId}&transactionId=${pendingTransactionId}&accessToken=${newAccessToken}&platform=${platform}`;
                        // console.log(url);
                        window.location.href = url;
                    } else {
                        navigate("/confirm-purchase");
                    }
                }
            }
        }else{
            navigate("/message");
        }        
    }

    onGameSupport() {
        let {transactionId} = formatQuery(this.props.q);
        // if (transactionId === "showqueryorder"){
        //     transactionId = cookie.load("_datatranstransactionId");
        // }
        // navigate(`/game-support/?transactionId=${transactionId}`);
        navigate(`/payment-support-addticket/?transactionId=${transactionId}`);
    }

    onPaymentSupport() {
        let {transactionId} = formatQuery(this.props.q);
        // if (transactionId === "showqueryorder"){
        //     transactionId = cookie.load("_datatranstransactionId");
        // }
        navigate(`/payment-support/?transactionId=${transactionId}`);
    }

    render() {
        return (
            <View ready={this.state.ready} status={this.state.status} message={this.state.message} q={this.props.q}
                  oldsuccess={this.state.oldsuccess} oldmessage={this.state.oldmessage}
                  adimage={this.state.adItem1.adimg} adurl={this.state.adItem1.adlink} adeventId={this.state.adItem1.eventId}
                  onContinue={this.onContinue.bind(this)}
                  onGameSupport={this.onGameSupport.bind(this)}
                  onPaymentSupport={this.onPaymentSupport.bind(this)}
                  gold={this.state.gold}
                  balance={this.state.balance}
                  transactionId={this.state.transactionId}
                  price={this.state.price}
                  currency={this.state.currency}
                  createdAt={this.state.createdAt}
                  updatedAt={this.state.updatedAt}
                  showTransactionId={this.state.showTransactionId}
                  userId={this.state.userId}
                  productId={this.state.productId}
                  isQueryOrder={this.state.isQueryOrder}
            />
        );
    }
}

export default connect(state => {
    return {session: state.session};
})(withLocation(PaymentResult));

async function queryShowNext() {
    // 是否还有下一笔，是，更新数据，进入本页
    // 提交本笔记录已显示
    await KaisakuApi.setShowQueryTransaction(cookie.load("_datatranstransactionId"), cookie.load("_accessToken"));
    let _datatrans = cookie.load("_datatrans");
    let _datatransindex = cookie.load("_datatransindex");
    _datatransindex++;
    console.log(_datatransindex);
    if (_datatransindex < _datatrans.length) {
        let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);
        cookie.save("_datatransindex", _datatransindex, {path: "/", expires: inFifteenMinutes});
        cookie.save("_datatranstransactionId", _datatrans[_datatransindex].transactionId, {
            path: "/",
            expires: inFifteenMinutes
        });
        return true;
    }else{
        // 三个项目都删除
        cookie.remove("_datatransindex");
        cookie.remove("_datatranstransactionId");
        cookie.remove("_datatrans");
        return false;
    }
}

function saveToCookie(q){
    let {pendingTransactionId, newAccessToken, newGameId, newAttributionId} = formatQuery(q);
    console.log(newAccessToken);
    if ((newAccessToken !== undefined) && (newGameId !== undefined) && (newAccessToken !== null) && (newGameId !== null)) {
        let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);
        cookie.save("_gameId", newGameId, {
            path: "/",
            expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
        });
        cookie.save("_accessToken", newAccessToken, {
            path: "/",
            expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
        });
        cookie.save("_transactionId", pendingTransactionId, {
            path: "/",
            expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
        });
        cookie.save("_attributionId", newAttributionId, {
            path: "/",
            expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
        });
    }
}